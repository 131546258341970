import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Bird from './twitter-logo.svg';

import { color, media } from '../../theme'

import TweetPhoto from '../../components/TweetPhoto'

const Wrapper = styled.div`
  display: flex;
`

const Content = styled.div`
  background-color: ${color.WHITE};
  border-radius: 0.5rem;
  flex: 1 0 0;
  padding: 1rem;
  ${({ active }) => active && `
    box-shadow: 0px 0px 16px 0px ${color.GRAY};
  `}
  ${media.twtr`
    padding: 1.25rem;
    border-radius: 0.75rem;
    ${({ active }) => active && `
      box-shadow: 0px 0px 24px 0px ${color.GRAY};
    `}
  `}
`

const TweetHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;
  ${media.twtr`
    margin-bottom: 1.5rem;
  `}
`

const Profile = styled.a`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-decoration: none;
`

const ProfileImg = styled.img`
  border-radius: 64px;
  height: 2.5rem;
  width: 2.5rem;
  margin-right: 0.5rem;
  ${media.twtr`
    height: 3rem;
    width: 3rem;
    margin-right: 1rem;
  `}
`

const User = styled.div`
  margin-right: 0.5rem;
`

const Name = styled.div`
  font-weight: 700;
  color: ${color.BLACK};
  line-height: 22px;
  font-size: 16px;
  ${media.twtr`
    font-size: 20px;
    margin-bottom: 0.25rem;
    line-height: 24px;
  `}
`

const ScreenName = styled.div`
  color: ${color.STEEL};
  font-size: 14px;
  ${media.twtr`
    font-size: 16px;
  `}
`

const TwitterLogo = styled.div`
  flex-shrink: 0;
  height: 28px;
  width: 28px;
`

const TweetMain = styled.div`
  ${media.twtr`
    margin-left: 4rem;
  `}
`

const Text = styled.div`
  font-size: 18px;
  line-height: 1.4;
  white-space: pre-wrap;
  & a {
    color: ${color.TWITTER};
    text-decoration: none;
  }
`
const TweetFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  ${media.twtr`
    margin-top: 1.25rem;
  `}
`

const ViewOnTwitter = styled.div`
  & > a {
    color: ${color.TWITTER};
    text-decoration: none;
    font-size: 13px;
    font-weight: 700;
    &:hover {
      color: ${color.TEAL};
    }
  }
`

const Date = styled.div`
  & > a {
    font-weight: bold;
    font-size: 15px;
    color: ${color.BLACK};
    text-decoration: none;
  }
  ${media.twtr`
    margin-left: 4rem;
  `}
`
const TweetCard = ({
  tweet,
  active,
  ...props
}) => {

  const {
    screenName,
    profileImg,
    name,
    text,
    mediaData,
    expandedUrl,
    url,
    date,
  } = tweet

  return (
    <Wrapper {...props}>
        <Content active={active}>
          <TweetHeader>
            <Profile
              href={`https://twitter.com/${screenName}`}
              target="_blank"
              rel="noreferrer"
            >
              <ProfileImg
                src={profileImg}
                alt={`@${screenName}`}
              />
              <User>
                <Name>{name}</Name>
                <ScreenName>@{screenName}</ScreenName>
              </User>
            </Profile>
            <TwitterLogo>
              <img
                src={Bird}
                alt="Twitter"
                height="28"
                width="28"
              />
            </TwitterLogo>
          </TweetHeader>
          <TweetMain>
            <Text>
              <div dangerouslySetInnerHTML={{ __html: text }} />
            </Text>
            {mediaData && mediaData.type === 'photo' &&
              <TweetPhoto
                // height={mediaData.height}
                // width={mediaData.width}
                src={mediaData.url}
                href={expandedUrl}
              />
            }
          </TweetMain>
          <TweetFooter>
            <Date>
              <a
                href={url}
                target="_blank"
                rel="noreferrer"
              >
                {date}
              </a>
            </Date>
            <ViewOnTwitter>
              <a
                href={url}
                target="_blank"
                rel="noreferrer"
              >
                View on Twitter
              </a>
            </ViewOnTwitter>
          </TweetFooter>
        </Content>
    </Wrapper>
  )
}

TweetCard.prototype = {
  tweet: PropTypes.shape({
    /** unique twitter username usually preceded by @  */
    screenName: PropTypes.string,
    /** url to user's profile image */
    profileImg: PropTypes.string,
    /** user's chosen name, can change on whim */
    name: PropTypes.string,
    /** actual tweet encoded text */
    text: PropTypes.string,
    /** data to render tweeted media like photos */
    mediaData: PropTypes.shape({
      /** so far, 'photo' */
      type: PropTypes.oneOf(['photo']),
      /** url to actual asset */
      url: PropTypes.string,
      /** TODO: not used yet, maybe for future expanding ui */
      height: PropTypes.number,
      /** TODO: not used yet, maybe for future expanding ui */
      width: PropTypes.number,
    }),
    /** url to tweet where media is expanded to show full height */
    expandedUrl: PropTypes.string,
    /** url to original tweet */
    url: PropTypes.string,
    /** D MMM YYYY when tweet was posted */
    date: PropTypes.string,
  })
}
export default TweetCard
