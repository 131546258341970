import React, { useRef, useState, useEffect, useCallback } from "react"
import { graphql } from "gatsby"
import { Virtuoso } from 'react-virtuoso'
import styled from "styled-components"
import { Waypoint } from 'react-waypoint';
import sortBy from 'lodash/sortBy';

import Goody from './goody.svg';
import SEO from "../../components/seo"
import Layout from "../../components/Layout"
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import IntroSection from "../../components/IntroSection"

import TweetCard from '../../components/TweetCard'

import { color, media } from "../../theme"

const StyledHeader = styled(Header)`
  width: calc(100vw - 48px);
  max-width: 38rem;
`

const StyledIntroSection = styled(IntroSection)`
  background-color: ${color.WHITE};
  border-bottom: 0;
  padding-bottom: 0;
`

const MainContent = styled.div`
  background-color: ${color.LIGHTEST_GRAY};
  padding-bottom: 32px;
`

// Note: margin-top sets faux padding under the IntroSection
const TwitterLayout = styled.div`
  width: calc(100vw - 32px);
  max-width: 38rem;
  margin: 16px auto 0;
  ${media.twtr`
    width: calc(100vw - 48px);
    margin: 24px auto 0;
  `}
`

const Blurb = styled.p`
  max-width: 480px;
  margin-bottom: 0.5rem;
  /* text-align: center; */
  ${media.twtr`
    max-width: 520px;
  `}
  /* ${media.twtr`
    max-width: 620px;
  `} */
`;

const StickyHeader = styled.div`
  position: sticky;
  top: -1px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${color.BLACK};
  /* transition: background-color 0.25s ease; */
  ${({ isSticking }) => isSticking && `
    border-bottom: 1px solid ${color.LIGHT_GRAY};
  `}
  background-color: ${color.WHITE};
  /* background-color: rgba(240, 245, 246, 0.9); */
  /* backdrop-filter: blur(2px); */
`

const StickyContent = styled.div`
  max-width: 38rem;
  display: flex;
  justify-content: space-between;
  width: 100vw;
  /* padding-left: 1rem; */
  ${media.twtr`
    width: calc(100vw - 48px);
    padding-left: 0;
  `}
  & > div {
    height: 40px;
  }
`

// scroll container
const JumpNav = styled.div`
  overflow: scroll;
  display: flex;
  justify-content: flex-start;
  align-items: center;  
`

const JumpLink = styled.div`
  font-size: 14px;
  ${media.twtr`
    font-size: 14px;
  `}
  font-weight: bold;
  color: ${color.TWITTER};
  cursor: pointer;
  margin-left: 1rem;
  &:last-child {
    padding-right: 1rem;
  }
  ${media.twtr`
    margin-left: 0;
    margin-right: 1rem;
    &:last-child {
      padding-right: 0;
      margin-right: 0;
    }
  `}
  &:hover {
    color: ${color.GREEN};
  }
`

const StickyMonthHeading = styled.div`
  display: flex;
  align-items: center;
  ${({ hide }) => hide && `
      visibility: hidden;
  `}
  white-space: nowrap;
  /* transition: all 100ms ease; */
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
  width: 125px;
  letter-spacing: 1px;
  color: ${color.BLACK};
  margin-right: 2rem;
  margin-left: 1rem;
  ${media.twtr`
    margin-left: 0;
  `}
`

const Tweet = styled.div`
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
`

const Comment = styled.div`
  font-weight: 700;
  font-style: italic;
  font-size: 13px;
  color: ${color.STEEL};
  margin-top: 0.75rem;
  margin-left: 1rem;
  margin-right: 1rem;
  & i {
    font-weight: 800;
    font-style: italic;
  }
  ${media.twtr`
    margin-left: 5rem;
    margin-right: 1.5rem;
  `}
`

const Sig = styled.span`
  white-space: nowrap;
  color: ${color.STEEL};
  margin-left: 0.5rem;
  & > img {
    margin-left: 0.5rem;
    height: 18px;
    width: 18px;
    line-height: 18px;
    vertical-align: middle;
  }
`

const GoodyLogo = styled.div`
margin-left: 0.5rem;
  display: inline-block;
  height: 18px;
  width: 18px;
  line-height: 18px;
  vertical-align: middle;
`

const StyledFooter = styled(Footer)``

const MonthHeader = styled.div`
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px;
  color: ${color.STEEL};
  padding-bottom: 1.5rem;
  padding-top: ${({ first }) => first ? '1rem' : '0.4rem'};
  ${media.twtr`
    font-size: 14px;
    padding-top: ${({ first }) => first ? '1.5rem' : '0.4rem'};
  `}
`

const generateJumpLinks = (groupData) => {
  
  const years = sortBy(groupData, 'fieldValue').reverse()

  let indexCounter = 0;
  const jumpLinks = years.map(year => {
    const index = indexCounter
    indexCounter = indexCounter + year.totalCount
    return {
      year: year.fieldValue,
      count: year.totalCount,
      index,
    }
  })
  
  return jumpLinks
}

/**
 * This wrapper component exists to fire Waypoint handlers
 * to update the month/year in the Sticky Header
 */
const WaypointWrapper = ({
  month,
  year,
  lastInMonth,
  handleWaypoint,
  offset
}) => {
  
  /**
   * handles updating when scrolling DOWN
   */
  const handleLeave = (props) => {
    // 1. don't proceed for the lastInMonth bottom waypoints
    // 2. don't proceed if scrolling UP... so do NOT proceed if
    //    the waypoint is CURRENTLY below the screen as we LEAVE the waypoint
    if (lastInMonth || props.currentPosition === 'below') return false
    // if the waypoint has left the screen and is CURRENTLY above the screen
    if (props.currentPosition === 'above') {
      handleWaypoint(month, year)
    }
  }

  /**
   * handles updating when scrolling UP
   */
  const handleEnter = ({ previousPosition }) => {
    // 1. don't proceed for the top waypoints (firstInMonth top waypoints)
    // 2. don't proceed if scrolling DOWN... so do NOT proceed if
    //    the waypoint was PREVIOUSLY below the screen as we ENTER the waypoint
    if (!lastInMonth || previousPosition === 'below') return false
  
    // NOTE: If you scroll really fast, the previousPosition can be undefined, so we should still update
    const isEnteringFromBelow = previousPosition === 'above' || typeof previousPosition === 'undefined'
    if (lastInMonth && isEnteringFromBelow) {
      // console.info('props.previousPosition === above')
      handleWaypoint(month, year);
    }
  }

  return (
    <>
      <Waypoint
        onLeave={handleLeave}
        onEnter={handleEnter}
        topOffset={offset}
        fireOnRapidScroll
      />
    </>
  )
}

const Page = ({ data }) => {
  const allTweets = data.allFunnyTweetsJson.nodes
  // console.info('allTweets: ', allTweets); // wf
  const groupData = data.allFunnyTweetsJson.group
  const jumpLinkData = generateJumpLinks(groupData);

  const [stickyDate, setStickyDate] = useState(`${allTweets[0].month} ${allTweets[0].year}`)
  const [hashedIndex, setHashedIndex] = useState(); // to put shadows between the tweet card when hash is in url
  const [stickyEngaged, setStickyEngaged] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);

  const virtuoso = useRef(null);

  const handleHashChange = useCallback(event => {
    if (window.location.hash) {
      const index = allTweets.findIndex(item => item.id === window.location.hash.substring(1));
      // setTimeout is the only way i could get the thing to scroll on initial load
      setTimeout(() => {
        virtuoso.current.scrollToIndex({
          index,
          align: 'center',
          behavior: 'auto',
        });
        setHashedIndex(index);
        setStickyEngaged(true)
        // be sure to update the header after we scoll to index
        const tweet = allTweets[index]
        setStickyDate(`${tweet.month} ${tweet.year}`)
      }, 0)
    }
  }, [allTweets])

  // scroll to if there's a hash
  useEffect(() => {
    handleHashChange()
  }, [handleHashChange])

  useEffect(() => {
    window.addEventListener('hashchange', handleHashChange);

    return function cleanup() {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [handleHashChange]);

  const updateHeader = (month, year) => {
    setStickyDate(`${month} ${year}`)
  }

  const handleJumpLink = (event) => {
    setIsScrolling(true)
    const index = event.target.getAttribute('data-index')
    // TODO: not react way, don't be lazy, make a subcomponent
    if (event.target.getAttribute('data-first') === 'true') {
      // if we click the top jumplink, just scroll all the way to the top
      window.scrollTo(0, 0)
    } else {
      virtuoso.current.scrollToIndex({
        index,
        align: 'start',
        behavior: 'auto',
      });
    }

    setTimeout(() => {
      updateHeader(allTweets[index].month, allTweets[index].year)
      setIsScrolling(false)
    }, 100)
  }

  return (
    <Layout bgColor="WHITE">
      <SEO
        title="Funny Parents on Twitter"
        description="A running list of the funniest tweets about parenting."
      />
      <StyledHeader bgColor="TEAL" />
      <StyledIntroSection
        twitterShareProps={{
          url: 'https://goodypoints.com/funny-parenting-tweets',
          options: {
            text: 'Funniest Parenting Tweets',
            via: 'GoodPointsApp',
            size: 'small'
          }
        }}
        heading="Funniest Tweets on Parenting"
        tight
      >
        <Blurb>
          We know how valuable keeping a sense of humor is to parenting young kids.
          So here's our running list of the funniest posts we could find on Twitter. Enjoy!
        </Blurb>
      </StyledIntroSection>
      
      <StickyHeader isSticking={stickyEngaged}>
        <StickyContent isSticking={stickyEngaged}>
          {stickyEngaged &&
            <StickyMonthHeading
              hide={isScrolling}
            >
            {stickyDate}
            </StickyMonthHeading>
          }
          <JumpNav isSticking={stickyEngaged}>
            {jumpLinkData.map((link, i) => {
              return (
                <JumpLink
                  key={link.year}
                  id={link.year}
                  data-index={link.index}
                  data-first={i === 0} // need to know when first is clicked
                  onClick={handleJumpLink}
                >
                  {link.year}
                </JumpLink>
              )
            })}
          </JumpNav>
        </StickyContent>
      </StickyHeader>
      <MainContent>
        <Waypoint
          onLeave={() => setStickyEngaged(true)}
          onEnter={() => setStickyEngaged(false)}
          topOffset={-16}
        />
        <TwitterLayout>
          <Virtuoso
            useWindowScroll
            data={allTweets}
            ref={virtuoso}
            itemContent={(index) => {
              const tweet = allTweets[index]
              return (
                <Tweet key={tweet.id}>
                  {tweet.subheading &&
                    <WaypointWrapper
                      month={tweet.month}
                      year={tweet.year}
                      handleWaypoint={updateHeader}
                      offset={0}
                    />
                  }
                  {tweet.subheading &&
                    <MonthHeader first={index === 0}>
                      {tweet.subheading} {tweet.year}
                    </MonthHeader>
                  }
                  <TweetCard
                    tweet={tweet}
                    active={index === hashedIndex}
                  />
                  {tweet.comment && tweet.comment.length &&
                    <Comment>
                      <span dangerouslySetInnerHTML={{ __html: tweet.comment }} />
                      <Sig>
                        –
                        <GoodyLogo>
                          <img
                            src={Goody}
                            alt="Goody Points app logo"
                          />
                        </GoodyLogo>
                      </Sig>
                    </Comment>
                  }
                  {tweet.last &&
                    <WaypointWrapper
                      month={tweet.month}
                      year={tweet.year}
                      lastInMonth
                      handleWaypoint={updateHeader}
                      offset={72}
                    />
                  }
                </Tweet>
              )
            }}
          />

        </TwitterLayout>
      </MainContent>
      <StyledFooter bgColor="BLACK" />
    </Layout>
  );
};

export const query = graphql`
  query FunnyQuery {
    allFunnyTweetsJson {
      nodes {
        heading
        subheading
        last
        year
        month
        text
        date(formatString: "D MMM YYYY")
        profileImg
        name
        screenName
        url
        id
        comment
        expandedUrl
        mediaData {
          height
          type
          url
          width
        }
      }
      group(field: year) {
        totalCount
        fieldValue
      }
    }
  }
`

export default Page