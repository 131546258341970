import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { color, media } from "../../theme"

const MAX_WIDTH = 504;

const A = styled.a`
  display: flex;
  border: 1px solid ${color.LIGHTER_GRAY};
  border-radius: 1rem;
  overflow: hidden;
  margin-top: 1rem;
  ${media.twtr`
    margin-top: 1.25rem;
  `}
`

const Img = styled.img`
  width: 100%;
  max-width: ${MAX_WIDTH}px;
  object-fit: cover;
  height: 100%;
  max-height: calc((100vw - 64px) * 0.75); /* force 4:3 */
  ${media.twtr`
    max-height: ${MAX_WIDTH * 0.75}px;
  `}
`

const TweetPhoto = ({
  src,
  href,
  // height,
  // width,
  ...props
}) => {

  // TODO: comment some of this back in if we want to do expansion on our side
  // force 4:3 
  // const maxHeight = width > height ? MAX_WIDTH * 0.75 : MAX_WIDTH * 1.333333;

  return (
    <A
      href={href}
      target="_blank"
      rel="noreferrer"
      {...props}
    >
      <Img
        // maxHeight={maxHeight}
        src={src}
        alt="tweet photo"
      />
    </A>
  )
}

TweetPhoto.propTypes = {
  src: PropTypes.string,
  href: PropTypes.string,
}
export default TweetPhoto
